.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logodesign {
  position: relative;
  height: 100%;
  width: 26%;
}
.logoimg {
  position: absolute;
  top: -76px;
  height: 203px;
  width: 300px;
  z-index: 1111;
}
.banner_img {
  width: 100%;
}
.banner_top {
  position: relative;
}
.banner_heading {
    position: absolute;
    top: 179px;
    left: 119px;
    width: 40%;   
}
.banner_heading_text {
  font-weight: 600;
  font-size: 30px;
}
.banner_heading_p {
  font-size: 20px;
  font-weight: 400;
}
.banner_heading_text1 {
  font-weight: 600;
  font-size: 30px;
}
.banner_heading_p1 {
  font-size: 20px;
  font-weight: 260;
}
.home_service {
  display: flex;
  align-items: center;
  padding: 20px 0 80px 0;
  gap: 0 10px;
}
.serviceimg {
  height: 104px;
  width: 250px;
}
.home_service2 {
  display: flex;
  align-items: center;
  gap: 0 10px;
}
.home_service1_heading {
  font-size: 20px;
  font-weight: 600;
  color: #222;
}
.home_service1_p {
  font-size: 14px;
  font-weight: 400;
  color: #222;
}
.section_heading {
  display: flex;
  justify-content: center;
}
.section_heading_text {
  font-size: 40px;
  font-weight: 600;
  color: #222;
  padding: 60px 0 60px 0;
}
.home_about_us {
  background-color: #1b0e0c;
}
.section_heading_text1{
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  padding: 60px 0 20px 0;
}
.home_about_us_pic_inner {
  display: flex;
  justify-content: center;
}
.home_about_us_text {
  font-size: 20px;
  font-weight: 200;
  color: #fff;
  width: 80%;
  text-align: center;
  padding-bottom: 80px;
}
.section_heading_text2{
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  padding: 60px 0 60px 0;
}
.brand_pic {
  height: 100px;
  width: 100%;
}
.brand_head {
  padding: 60px 0 60px 0;
}

@media screen and (max-width: 480px) {
  .banner_img {
    height: 250px;
    object-position: center;
  }
  .banner_heading {
    position: absolute;
    top: 60px;
    left: 50px;
    width: 50%;
  }
  .banner_heading_text1 {
    font-weight: 600;
    font-size: 17px;
  }
  .banner_heading_text {
    font-weight: 600;
    font-size: 17px;
  }
  .banner_heading_p {
    font-size: 14px;
    font-weight: 400;
  }
  .banner_heading_p1 {
    font-size: 14px;
    font-weight: 400;
  }
  .section_heading_text1 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding: 35px 0 35px 0;
  }
  .home_about_us_text {
    font-size: 15px;
    font-weight: 200;
    color: #fff;
    width: 82%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 35px;
  }
  .section_heading_text {
    font-size: 20px;
    font-weight: 600;
    color: #222;
    padding: 35px 0 34px 0;
  }
  .home_service {
    display: flex; 
    flex-direction: column;
    padding: 0px 0 0px 0;
  }
  .serviceimg {
    height: 90px;
    width: 100px;
  }
  .home_service2 {
    display: flex;
    align-items: center;
    gap: 0 10px;
    flex-direction: column;
    align-items: center;
  }
  .home_service1_text {
    text-align: center;
    padding-bottom: 26px;
  }
  .home_service1_heading {
    font-size: 20px;
    font-weight: 600;
    color: #222;
    padding: 10px 0 10px 0;
  }
  .brand_pic {
    height: 63px;
    width: 74%;
  }
  .respon_product {
    justify-content: center;
  }
  .logoimg {
    position: absolute;
    top: 2px;
    height: 100%;
    width: 185px;
    z-index: 1111;
    left: 19px;
    object-fit: cover;
  }
  .brand_head {
    padding: 60px 0 60px 20px ;
  }
  .logodesign {
    position: relative;
    height: 100%;
    width: 64%;
  }
  .mobile_nav_logo {
    position: absolute;
    right: 0 !important;
  }
  .logomenu {
    position: absolute;
    top: -42px;
    height: 85px;
    width: 300px;
    z-index: 1111;
    left: -82px;
  }
}
@media screen and (max-width: 768px) {
  .banner_img {
    height: 250px;
    object-position: center;
  }
  .banner_heading {
    position: absolute;
    top: 29px;
    left: 19px;
    width: 50%;
  }
  .banner_heading_text1 {
    font-weight: 600;
    font-size: 17px;
  }
  .banner_heading_text {
    font-weight: 600;
    font-size: 17px;
  }
  .banner_heading_p {
    font-size: 14px;
    font-weight: 400;
  }
  .banner_heading_p1 {
    font-size: 14px;
    font-weight: 400;
  }
  .section_heading_text1 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding: 35px 0 35px 0;
  }
  .home_about_us_text {
    font-size: 15px;
    font-weight: 200;
    color: #fff;
    width: 82%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 35px;
  }
  .section_heading_text {
    font-size: 20px;
    font-weight: 600;
    color: #222;
    padding: 35px 0 34px 0;
  }
  .home_service {
    display: flex; 
    flex-direction: column;
    padding: 0px 0 0px 0;
  }
  .serviceimg {
    height: 90px;
    width: 100px;
  }
  .home_service2 {
    display: flex;
    align-items: center;
    gap: 0 10px;
    flex-direction: column;
    align-items: center;
  }
  .home_service1_text {
    text-align: center;
    padding-bottom: 26px;
  }
  .home_service1_heading {
    font-size: 20px;
    font-weight: 600;
    color: #222;
    padding: 10px 0 10px 0;
  }
  .brand_pic {
    height: 63px;
    width: 74%;
  }
  .respon_product {
    justify-content: center;
  }
  .logodesign {
    position: relative;
    height: 100%;
    width: 64%;
}
.logoimg {
    position: absolute;
    top: 2px;
    height: 100%;
    width: 185px;
    z-index: 1111;
    left: 19px;
    object-fit: cover;
  }
  .mobile_nav_logo {
    position: absolute;
    right: 0;
  }
  .logomenu {
    position: absolute;
    top: -42px;
    height: 85px;
    width: 300px;
    z-index: 1111;
    left: -82px;
    overflow: hidden;
  }
}

